<template>
    <div class="bigcard">
        <div :class="['avatar', { concluso: imageTag == 'concluso' }]">
            <div v-if="imageTag" :class="['imageTag', imageTag]">{{ $t(imageTag) }}</div>
            <Image v-if="cover" :image="cover" :width="width" :height="height" :mobileHeight="mobileHeight" imageClass="cover" :key="cover.id" />
        </div>

        <div class="contentBox" v-if="titolo">
            <div class="tagBoxCont" v-if="tassonomia">
                <div class="tagBox mini">{{ tassonomia }}</div>
            </div>
            <div class="title4">{{ titolo.replace(/\+/g, '+&#8203;') }}</div>
        </div>

        <a v-if="link && isExternal" class="overlink" :href="link" target="_blank" @click="clickedLink" :download="linkName" :aria-label="titolo"></a>
        <NuxtLinkLocale v-if="link && !isExternal" class="overlink" :to="link" @click="clickedLink" :aria-label="titolo"></NuxtLinkLocale>
    </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['clickedLink'])

interface Props {
    cover?: any,
    tag?: string,
    titolo?: string,
    tassonomia?: string,
    link?: any,
    linkName?: string,
    isExternal?: boolean,
    imageTag?: string | null,
    height?: number,
    mobileHeight?: number | null,
    width?: number,
}
withDefaults(defineProps<Props>(), {
    width: 260,
    height: undefined
})

const clickedLink = () => {
    emit('clickedLink')
}
</script>

<style lang="scss" scoped>
.bigcard {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: var(--color-background-image);
    border-radius: var(--size-card-radius);
    overflow: hidden;

    /*&:deep(img) {
        transform: scale3d(1, 1, 1);
        transition: transform 150ms 0ms ease-in-out;
    }*/

    .avatar {
        position: relative;
        height: 100%;
        width: 100%;

        .imageTag {
            position: absolute;
            z-index: 1;

            font-weight: 800;
            text-transform: uppercase;

            padding: 10px 12px;
            top: 10px;
            left: 10px;
            border-radius: 8px;

            &.concluso {
                background-color: var(--color-background-gray-logo);
                color: white;
            }
        }

        &.concluso {
            filter: saturate(0);
        }
    }

    .tagBoxCont {
        transform: translate3d(-10px, calc(0px - var(--margin-default) - (var(--margin-mini) * 2 + var(--font-line-mini)) / 2), 0);
        height: 0;

        .tagBox {
            display: inline-block;
            background-color: var(--color-azure-dark);

            padding: var(--margin-mini) var(--margin-gap);
            border-radius: calc((var(--margin-mini) * 2 + var(--font-line-mini)) / 2);

            color: var(--color-text-contrast);
            text-wrap: nowrap;
            font-weight: 600;
        }
    }

    .contentBox {
        background-color: var(--color-background-box);
        position: absolute;
        bottom: var(--margin-gap);
        left: var(--margin-gap);
        padding: var(--margin-default);

        border-radius: var(--size-card-radius-inner);
        max-width: calc(100% - (var(--margin-gap) * 2));
        box-sizing: border-box;
    }

    /*&:hover {
        &:deep(img) {
            transform: scale3d(1.025, 1.025, 1)
        }
    }*/
}
</style>